































import Vue from 'vue'

import InputText from '@/components/InputText.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default Vue.extend({
	name: 'FormLogin',
	components: {
		InputText,
		LoadingSpinner,
	},
	data: () : {
		fields: Collection,
	} => {
		return {
			fields: {
				email: '' as string,
				password: '' as string,
			},
		}
	},
	computed: {
		loading(): boolean {
			return this.$store.getters['misc/loading']
		},
		error(): string {
			return this.$store.getters['misc/error']
		}
	},
	methods: {
		submitForm(fields: Collection): void {
			this.$store.dispatch('user/login', fields)
				.then(() => {
					if (this.$route.name === 'login') {
						if (this.$route.query.from && this.$route.query.from !== 'login') {
							const from = String(this.$route.query.from)
							this.$store.dispatch('checkin/loadForms')
							this.$router.push({ name: from })
						} else {
							this.$router.push({name: 'main'})
						}
					}
				})
		},
	},
})
