







import Vue from 'vue'
import FormPassword from '@/components/FormPassword.vue'

export default Vue.extend({
	name: 'PartialLoginPassword',
	components: {
		FormPassword,
	},
	metaInfo: () => {
		return {
			title: 'Forgot Password'
		}
	},
})
