







import Vue from 'vue'
import FormLogin from '@/components/FormLogin.vue'

export default Vue.extend({
	name: 'PartialLogin',
	components: {
		FormLogin,
	},
	metaInfo: () => {
		return {
			title: 'Sign In',
		}
	}
})
