



































import Vue from 'vue'

import InputText from '@/components/InputText.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default Vue.extend({
	name: 'FormPassword',
	components: {
		InputText,
		LoadingSpinner,
	},
	data: () : {
		fields: Collection,
		success: string
	} => {
		return {
			fields: {
				email: '' as string
			},
			success: '',
		}
	},
	computed: {
		loading(): boolean {
			return this.$store.getters['misc/loading']
		},
		error(): string {
			return this.$store.getters['misc/error']
		}
	},
	methods: {
		submitForm(fields: Collection): void {
			if (!fields.email) return

			this.$store.dispatch('user/passwordReset', fields)
				.then((success: string) => {
					this.success = success
				})
		}
	}
})
